import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import useStore from '../store';

const Login = () => {
	const [email, setEmail] = useState('operator@ubcab.mn');
	const [password, setPassword] = useState('');
	const [showError, setShowError] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const setLoginInfo = useStore(store => store.auth.setLoginInfo);

	const handleInputChange = (e, type) => {
		const value = e.target.value;
		if (type === 'email') {
			setEmail(value);
		} else if (type === 'password') {
			setPassword(value);
		}
	};

	const login = async () => {
		setShowError(false);
		setErrorText('');

		if (!email && !password) {
			setShowError(true);
			setErrorText('И-майл эсвэл нууц үгээ оруулна уу');
			return;
		} else if (!email) {
			setShowError(true);
			setErrorText('И-майл хаягаа оруулна уу');
			return;
		} else if (!password) {
			setShowError(true);
			setErrorText('Нууц үгээ оруулна уу');
			return;
		}

		setIsLoading(true);

		const docRef = doc(db, 'states', 'current_state');
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setLoginInfo(docSnap.data().collection_name);
		}

		signInWithEmailAndPassword(auth, email, password)
			.then(() => {
				setTimeout(() => {
					setIsLoading(false);
				}, 1000);
			})
			.catch(error => {
				setShowError(true);
				setErrorText('Нууц үг буруу байна');
			});
	};

	const handleEnterKey = e => {
		if (e.key === 'Enter') {
			login();
		}
	};

	return (
		<div className='flex flex-col px-4 items-center justify-center h-screen w-screen'>
			<img
				src='/images/ubcab-logo-black.svg'
				alt='Logo'
				className='w-[200px] sm:w-[256px]'
			/>
			<div className='text-xl sm:text-2xl text-black/50 font-[GIP] font-semibold mt-3'>Driver Coupon</div>

			<div className='relative flex w-full sm:w-[500px] flex-wrap items-stretch font-[GIP] pt-10'>
				<span className='z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent text-base items-center justify-center pl-4 py-4'>
					<i>
						<img
							src='/images/email-icon.svg'
							alt='Email Icon'
						/>
					</i>
				</span>
				<input
					type='email'
					placeholder='И-майл хаяг'
					className='px-3 py-3 placeholder-blueGray-300 text-black relative bg-white text-2xl font-medium border-2 rounded-2xl shadow outline-none focus:outline-none w-full pl-14 focus:border-[#F9C623]'
					onChange={e => handleInputChange(e, 'email')}
					value={email}
					autoComplete='username'
				/>
			</div>

			{/* Password Input */}
			<div className='relative flex w-full sm:w-[500px] flex-wrap items-stretch font-[GIP] pt-5'>
				<span className='z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 bg-transparent text-base items-center justify-center pl-4 py-4'>
					<i>
						<img
							src='/images/password-icon.svg'
							alt='Password Icon'
						/>
					</i>
				</span>
				<input
					type='password'
					placeholder='Нууц үг'
					className='px-3 py-3 placeholder-blueGray-300 text-black relative bg-white text-2xl font-medium border-2 rounded-2xl shadow outline-none focus:outline-none w-full pl-14 focus:border-[#F9C623]'
					onChange={e => handleInputChange(e, 'password')}
					onKeyDown={handleEnterKey}
				/>
			</div>

			{/* Error Message */}
			{showError && <div className='text-red-500 text-lg font-[GIP] font-semibold mt-3 '>{errorText}</div>}

			{/* Loading Overlay */}
			{isLoading && (
				<div className='fixed inset-0 flex items-center justify-center bg-white z-20'>
					<img
						src='/images/ubcab-logo-black.svg'
						alt='Logo'
						className='animate-pulse'
					/>
				</div>
			)}

			<button
				onClick={login}
				className='pt-10 w-full sm:w-[500px]'
				disabled={isLoading}
			>
				<div className={` text-center text-2xl font-semibold font-[GIP] items-center py-4 bg-[#F9C623] rounded-xl hover:bg-[#F9C623]/80'`}>Нэвтрэх</div>
			</button>
		</div>
	);
};

export default Login;
