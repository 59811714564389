import { create } from 'zustand';
import { mergeDeepLeft } from 'ramda';
import { createAuthSlice } from './slices/auth';
import { immer } from 'zustand/middleware/immer';
import { devtools, persist } from 'zustand/middleware';

const useStore = create()(
	devtools(
		persist(
			immer((...a) => ({
				...createAuthSlice(...a),
			})),
			{
				name: 'ub-eats-coupon',
				merge: (persistedState, currentState) => mergeDeepLeft(persistedState, currentState),
			}
		)
	)
);

export default useStore;
