import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase';
import useStore from '../store';

function Navbar() {
  const collectionName = useStore((state) => state.auth.current_collection);

  const logOut = () => {
    signOut(auth)
      .then(() => {})
      .catch((error) => {});
  };

  return (
    <div className='px-8 py-6 bg-white flex justify-between'>
      <div className='flex flex-row gap-5 items-center'>
        <a className='cursor-pointer' href='/'>
          <img
            src='/images/ubcab-logo-black.svg'
            alt='logo'
            className='h-8 cursor-pointer'
          />
        </a>
        <div className='w-[1px] h-full bg-[#CCCCCC]'></div>
        <div className='text-xl font-semibold font-[GIP] text-[#606061]'>
          {collectionName}
        </div>
      </div>

      <img
        onClick={logOut}
        src='/images/log-out.svg'
        alt='logout'
        className='h-10 cursor-pointer'
      />
    </div>
  );
}

export default Navbar;
