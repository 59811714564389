import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import { db } from '../config/firebase';

const Stats = () => {
	const [showOptions, setShowOptions] = useState(false);
	const [selectedOption, setSelectedOption] = useState('');
	const [optionList, setOptionList] = useState([]);
	const [statisticInfo, setStatisticInfo] = useState({});
	const [csvData, setCsvData] = useState([]);

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		if (selectedOption) {
			filterByCollection();
		}
	}, [selectedOption]);

	const fetchData = async () => {
		const docRef = doc(db, 'states', 'collection_name_list');
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setOptionList(docSnap.data()?.collections);
		}
	};

	const handleSelect = value => {
		setSelectedOption(value);
		setShowOptions(false);
	};

	const toggleOptions = () => {
		setShowOptions(!showOptions);
	};

	const filterByCollection = async () => {
		const q = query(collection(db, `${selectedOption}`), where('used_coupon', '==', true));
		const collectionSnap = await getDocs(collection(db, `${selectedOption}`));
		const filteredItemSnap = await getDocs(q);
		const tempData = [];
		filteredItemSnap.forEach(e => {
			tempData.push(e.data());
		});

		setCsvData(tempData);
		setStatisticInfo({
			overall: collectionSnap.size,
			userCoupon: filteredItemSnap.size,
		});
	};

	const downloadCsv = () => {
		const data = [['name', 'phone_number', 'car_registration_number', 'level_code']];

		csvData.forEach(e => {
			data.push([e.name, e.phone_number, e.car_registration_number, e.level_code]);
		});

		const csvContent = data.map(row => row.join(',')).join('\n');

		const blob = new Blob([csvContent], { type: 'text/csv' });

		const link = document.createElement('a');

		link.href = URL.createObjectURL(blob);
		link.download = 'driver-coupon.csv';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<div className=''>
			<Navbar />
			<div className='flex flex-col items-center justify-center mt-20 px-4'>
				<div className='relative w-full sm:w-[70%] md:w-[50%] lg:w-[35%] inline-block'>
					<button
						type='button'
						onClick={toggleOptions}
						className={`w-full rounded-[20px] p-5 flex items-center gap-5 ${
							showOptions ? 'border-[#F9C623]' : 'border-[#808080]'
						} border-2 focus:outline-none`}
					>
						<img
							src='/images/pack.svg'
							alt='Dropdown Icon'
						/>

						{selectedOption ? <span className='text-3xl'>{selectedOption}</span> : <span className='text-[#0000004D] text-3xl'>Oн - Сар</span>}
					</button>

					{showOptions && (
						<div className='absolute z-50 px-4 shadow-[0_4px_4px_0px_rgba(0,0,0,0.4)] box-shadow-custom bg-white w-full rounded-[20px] mt-2 py-2'>
							{optionList.map((name, index) => {
								return (
									<div
										key={index}
										onClick={() => {
											handleSelect(name);
											setShowOptions(false);
										}}
										className={`flex items-center cursor-pointer px-4 py-2 ${
											index !== optionList.length - 1 && 'border-b border-[#0000004D]'
										}`}
									>
										{name}
									</div>
								);
							})}
						</div>
					)}
				</div>

				{statisticInfo?.overall && (
					<div className='border-2 relative mt-8 p-4 rounded-[20px] border-[#808080] flex flex-col gap-2 font-semibold text-[22px] w-full sm:w-[70%] md:w-[50%] lg:w-[35%]'>
						<p className='text-[#808080] text-[20px] font-medium mb-2'>{selectedOption} -тайлан</p>
						<div>Нийт жолооч: {statisticInfo.overall} </div>
						<div>
							Ашигласан копун: <span className='text-[#F9C623]'>{statisticInfo.userCoupon}</span>
							<span className='text-[#808080] ml-2'>({Math.round((statisticInfo.userCoupon * 100) / statisticInfo.overall)}%)</span>
						</div>
						<div>
							Ашиглаагүй копун: <span className='text-[#F92323]'>{statisticInfo.overall - statisticInfo.userCoupon} </span>
							<span className='text-[#808080] ml-1'>({100 - Math.round((statisticInfo.userCoupon * 100) / statisticInfo.overall)}%)</span>
						</div>

						<div
							onClick={downloadCsv}
							className='absolute top-3 text-[14px] bg-[#F9C623] cursor-pointer py-1 px-3 rounded-[10px] text-white right-3'
						>
							download
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default Stats;
