import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import Modal from '../components/Modal';
import useStore from '../store';

const Home = () => {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const isButtonDisabled = phoneNumber.length !== 8;
	const [searchPerformed, setSearchPerformed] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalInfo, setModalInfo] = useState({});
	const updated_at_timestamp = serverTimestamp();
	const collectionName = useStore(state => state.auth.current_collection);

	const handlePhoneNumberChange = e => {
		let value = e.target.value;
		value = value.slice(0, 8);
		setPhoneNumber(value);
	};

	const handleSearch = async () => {
		setIsLoading(true);
		setSearchPerformed(true);

		const docRef = doc(db, `${collectionName}`, `${phoneNumber}`);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setSearchResults([
				{
					...docSnap.data(),
					id: docSnap.id,
				},
			]);
		}
		setIsLoading(false);
	};

	const openModal = data => {
		setIsModalOpen(true);
		setModalInfo(data);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setModalInfo({});
	};

	const handleCoupon = async () => {
		if (!modalInfo.id) return;
		const washingtonRef = doc(db, `${collectionName}`, modalInfo.id);
		await updateDoc(washingtonRef, {
			updated_at: updated_at_timestamp,
			used_coupon: true,
		});
		saveUsedInfo(modalInfo);
		setIsModalOpen(false);
		handleSearch();
		setPhoneNumber('');
	};

	const saveUsedInfo = async data => {
		const date = new Date();
		const dateFormat = `${date.getFullYear()}_${String(date.getMonth() + 1).padStart(2, '0')}_${String(date.getDate()).padStart(2, '0')}`;

		const docRef = doc(db, `log_info`, `${dateFormat}`);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			await setDoc(doc(db, 'log_info', `${dateFormat}`), {
				user_info: [...docSnap.data().user_info, { ...data }],
			});
		} else {
			await setDoc(doc(db, `log_info`, `${dateFormat}`), {
				user_info: [{ ...data }],
			});
		}
	};

	const convertDate = data => {
		const { nanoseconds, seconds } = data;
		const date = new Date(seconds * 1000 + nanoseconds / 1000000);
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hour = String(date.getHours()).padStart(2, '0');
		const minute = String(date.getMinutes()).padStart(2, '0');
		return `${year}-${month}-${day} ${hour}:${minute}`;
	};

	return (
		<div className=''>
			<Navbar />
			<div className='mt-20 md:space-y-10 flex flex-col items-center'>
				<div className='flex items-center justify-center flex-wrap justify-items-center gap-10'>
					<div className='py-2 px-4 md:py-5 md:px-[30px]  bg-white flex gap-2 rounded-[20px] border-2  '>
						<img
							src='/images/search.svg'
							alt=''
						/>

						<input
							className='bg-transparent [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border-none text-xl md:text-3xl font-semibold focus:outline-none'
							type='number'
							placeholder='Утасны дугаар'
							value={phoneNumber}
							onChange={handlePhoneNumberChange}
							onWheel={e => e.preventDefault()} // Disable scroll wheel
							onKeyDown={e => {
								if (e.key === 'Enter') {
									handleSearch();
								}
								if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
									e.preventDefault();
								}
							}}
						/>
						<div className='w-full flex items-center justify-end text-[#ccc] text-[40px]'>
							<div
								onClick={() => setPhoneNumber(false)}
								className='cursor-pointer'
							>
								&times;
							</div>
						</div>
					</div>

					<button
						className={` ${isButtonDisabled && 'opacity-40'} font-semibold text-2xl md:text-3xl rounded-[20px] py-4 px-7 md:py-5 md:px-[30px]
						bg-[#F9C623]
						}`}
						onClick={handleSearch}
						disabled={isButtonDisabled}
					>
						Хайх
					</button>
				</div>
				{isLoading && <p className='text-3xl font-bold'>Ачааллаж байна....</p>}{' '}
				{searchPerformed && searchResults.length === 0 && !isLoading && (
					<div className='flex flex-wrap justify-center text-center px-2 gap-2'>
						<img
							src='/images/sad.svg'
							alt=''
						/>

						<p className='text-xl md:text-3xl text-[#00000080] font-semibold'>"{phoneNumber}" дугаартай жолооч олдсонгүй</p>
					</div>
				)}
				{searchResults.map((result, index) => (
					<div className='w-[90%] xl:w-[40%] px-5 py-[30px] my-10 bg-white rounded-[25px] border-2'>
						<div
							className='flex flex-col items-center text-2xl md:text-3xl w-full space-y-5 font-semibold'
							key={index}
						>
							<div className='space-y-[14px] text-center'>
								<p>{result.name}</p>
								<div className='justify-center flex gap-3'>
									<img
										src='/images/phone.svg'
										alt=''
									/>

									<p> {result.phone_number}</p>
								</div>
								<div className='justify-center flex gap-3'>
									<img
										src='/images/car.svg'
										alt=''
									/>
									<p>{result.car_registration_number}</p>
								</div>
							</div>
							<div className='h-[0.5px] w-[80%] bg-[#000000]'></div>

							<p>Купоны төлөв:</p>
							{result.used_coupon ? (
								<p className='rounded-[30px] text-[18px] md:text-3xl text-white bg-[#F92323] px-5 py-2.5'>
									Ашигласан: {convertDate(result.updated_at)}{' '}
								</p>
							) : (
								<p className='rounded-[30px] text-white bg-[#23F95F] px-5 py-2.5'>Ашиглаагүй</p>
							)}
							<div className='h-[0.5px] w-[80%] bg-[#000000]'></div>

							<button
								className={`${
									result.used_coupon && 'opacity-40'
								} font-semibold text-2xl md:text-3xl rounded-[20px] py-5 px-[30px] bg-[#F9C623]`}
								onClick={() => {
									if (result.used_coupon) return;
									openModal(result);
								}}
								disabled={isButtonDisabled}
							>
								Купон ашиглах
							</button>
						</div>
					</div>
				))}
			</div>
			<Modal
				isOpen={isModalOpen}
				closeModal={closeModal}
				phoneNumber={modalInfo.phone_number}
				handleCoupon={handleCoupon}
			/>
		</div>
	);
};
export default Home;
