import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../components/Navbar';
import { db } from '../config/firebase';

const Logs = () => {
	const [selectedOption, setSelectedOption] = useState('');
	const [optionList, setOptionList] = useState({});
	const inputRef = useRef(null);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		const querySnapshot = await getDocs(collection(db, 'log_info'));
		const collectionData = {};
		querySnapshot.forEach(doc => (collectionData[doc.id] = doc.data().user_info));

		setOptionList(collectionData);
	};

	const onChangeInput = e => {
		const originalDateString = e.target.value;
		const dateComponents = originalDateString.split('-').join('_');
		setSelectedOption(dateComponents);
	};

	return (
		<div className=''>
			<Navbar />
			<div className='flex flex-col items-center justify-center mt-20 px-4'>
				<div className='relative w-full sm:w-[70%] md:w-[50%] lg:w-[35%] inline-block'>
					<button
						type='button'
						className={`w-full rounded-[20px] p-5 flex items-center gap-5 border-[#F9C623] border-2 focus:outline-none`}
					>
						<img
							src='/images/pack.svg'
							alt='Dropdown Icon'
						/>
						<input
							className='outline-none w-full bg-transparent'
							ref={inputRef}
							onChange={onChangeInput}
							type={'date'}
						/>
					</button>
				</div>

				{selectedOption && (
					<div className='border-2 mt-8 max-h-[400px] overflow-auto p-4 rounded-[20px] border-[#808080] flex flex-col gap-2 font-semibold text-[22px] w-full sm:w-[70%] md:w-[50%] lg:w-[35%]'>
						<p className='text-[#808080] text-[20px] font-medium mb-2'>{selectedOption} -тайлан</p>
						<div>Нийт жолооч: {optionList?.[selectedOption]?.length || 0} </div>
						{optionList?.[selectedOption]?.map((userInfo, index) => {
							return (
								<div
									key={index}
									className='flex gap-3'
								>
									<p>{userInfo.name}</p>
									<p className='text-[#F9C623]'>{userInfo.id}</p>
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};
export default Logs;
