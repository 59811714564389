import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './views/login';
import Home from './views/home';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebase';
import { useEffect, useState } from 'react';
import Admin from './views/admin';
import Stats from './views/stats';
import Logs from './views/logs';
import { Loading } from './components/Loading';

function App() {
	const [isLogin, setIsLogin] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		const unlisten = onAuthStateChanged(auth, user => {
			if (user) {
				setIsLogin(true);
			} else {
				setIsLogin(false);
			}
			setIsLoading(false);
		});

		return () => {
			unlisten();
		};
	}, []);

	const getRoutes = () => {
		if (!isLogin) {
			return (
				<Route
					path='/'
					element={<Login />}
				/>
			);
		}

		return (
			<>
				<Route
					path='/'
					element={<Home />}
				/>
				<Route
					path='/admin'
					element={<Admin />}
				/>
				<Route
					path='/stat'
					element={<Stats />}
				/>
				<Route
					path='/log'
					element={<Logs />}
				/>
			</>
		);
	};

	return (
		<BrowserRouter>
			{isLoading && <Loading />}
			<Routes>{getRoutes()}</Routes>
		</BrowserRouter>
	);
}

export default App;
