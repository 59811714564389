import React from 'react';

const Modal = ({ isOpen, closeModal, phoneNumber, handleCoupon }) => {
	if (!isOpen) return null;

	return (
		<div className='fixed  top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75 z-50'>
			<div className='bg-white rounded-lg p-8 w-[90%] md:w-[40%] space-y-[15px]'>
				<p className='text-xl md:text-2xl font-bold text-[#474747]'>
					Та <span className='text-black font-extrabold	'>“{phoneNumber}”</span> дугаартай жолоочийн купоныг ашиглахдаа итгэлтэй байна уу?
				</p>
				<p className=' text-[#474747] text-xl'>Нэг ашигласан тохиолдолд буцаах боломжгүйг анхаарна уу.</p>

				<div className='flex gap-3 justify-end'>
					<button
						className='border-[1px] font-semibold text-base rounded-[10px] py-2.5 px-9 border-[#D5DCE3]'
						onClick={closeModal}
					>
						Болих
					</button>

					<button
						onClick={handleCoupon}
						className='border-[1px] font-semibold text-base rounded-[10px] py-2.5 px-9 bg-[#F9C623]'
					>
						Ашиглах
					</button>
				</div>
			</div>
		</div>
	);
};

export default Modal;
