import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../config/firebase';
import { Loading } from '../components/Loading';

const Admin = () => {
	const [stateInfo, setStateInfo] = useState({});
	const [isLoading, setIsLogining] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [collectionName, setCollectionName] = useState('');

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		const querySnapshot = await getDocs(collection(db, 'states'));
		let collectionData = {};

		querySnapshot.forEach(doc => {
			collectionData[doc.id] = doc.data();
		});

		setStateInfo(collectionData);
	};

	const onChangeFile = file => {
		if (!collectionName) {
			alert('Please enter collection name');
			return;
		}
		parseCsv(file.target.files[0]);
	};

	const parseCsv = file => {
		const reader = new FileReader();

		reader.onload = e => {
			const result = e.target.result;
			const lines = result.split('\n');
			const data = lines.map(line => line.split(','));

			addNew(data);
		};

		reader.readAsText(file);
	};

	const addNew = async data => {
		setIsLogining(true);

		for (let i = 1; i < data.length; i++) {
			await setDoc(doc(db, `${collectionName}`, `${data[i][2].replace(/"/g, '')}`), {
				name: data[i][1].replace(/"/g, ''),
				phone_number: data[i][2].replace(/"/g, ''),
				car_registration_number: data[i][3].replace(/"/g, '').trim(),
				level_code: data[i][0].replace(/"/g, ''),
				used_coupon: false,
			});
		}

		setIsLogining(false);
		addCollectionName();
		alert('Done');
	};

	const addCollectionName = async () => {
		await setDoc(doc(db, 'states', `collection_name_list`), {
			collections: [...stateInfo?.collection_name_list?.collections, collectionName],
		});
		fetchData();
	};

	const changeCurrentState = async () => {
		if (!isModalOpen) return;
		await setDoc(doc(db, 'states', `current_state`), {
			collection_name: isModalOpen,
		});
		setIsModalOpen(false);
		fetchData();
	};

	return (
		<div className='flex flex-col m-auto w-[300px] gap-2 mt-6'>
			<p>Collection-ий нэрийг өгсний дараагаар .csv өргөтгөлтэй file сонгон оруулна уу!</p>

			<input
				value={collectionName}
				onChange={e => {
					setCollectionName(e.target.value);
				}}
				placeholder='Collection name'
				type={'text'}
				className='border border-black'
			/>
			<input
				accept='.csv'
				type={'file'}
				onChange={onChangeFile}
			/>

			<div className='mt-10'>
				<div>collection name</div>
				{stateInfo?.collection_name_list?.collections?.map((name, index) => {
					return (
						<div
							onClick={() => setIsModalOpen(name)}
							className={`cursor-pointer ${stateInfo?.current_state?.collection_name === name && 'text-[#F9C623]'}`}
							key={index}
						>
							{name}
						</div>
					);
				})}
			</div>

			{isLoading && <Loading />}

			{isModalOpen && (
				<div className='flex items-center flex-col justify-center left-0 top-0 bg-black/40 fixed h-screen w-screen'>
					<button
						onClick={changeCurrentState}
						className='text-white'
					>
						solih
					</button>
					<button
						onClick={() => setIsModalOpen(false)}
						className='text-white'
					>
						bolih
					</button>
				</div>
			)}
		</div>
	);
};

export default Admin;
