export const Loading = () => {
	return (
		<div className='fixed inset-0 flex items-center justify-center bg-white z-20'>
			<img
				src='/images/ubcab-logo-black.svg'
				alt='Logo'
				className='animate-pulse'
			/>
		</div>
	);
};
