export const createAuthSlice = (set, get) => {
	return {
		auth: {
			current_collection: null,
			setLoginInfo: collectionName => {
				set(state => {
					state.auth.current_collection = collectionName;
				});
			},
		},
	};
};
